@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css);
@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* icons */

/* .light-theme {
  --body-color: #fff;
  --white-color: #000;
  --theme-color: #ff6431;
  --black-color: #fff;
  --search-color: #eeeeee;
  --fire-bubble: #faf8f8;
  --light-grey: #f2f2f2;
  --deam-white: #000;
} */
:root {
  --body-color: #141217;
  --white-color: #fff;
  /* --theme-color: #ff6431; */
  --theme-color: #4481eb;
  --black-color: #000;
  --search-color: #272933;
  --fire-bubble: rgba(59, 65, 79, 0.5);
  --light-grey: rgba(90, 94, 104, 0.5);
  --deam-white: rgba(255, 255, 255, 0.5);
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body {
  background-color: #141217;
  background-color: var(--body-color);
  color: #fff;
  color: var(--white-color);
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  line-height: 24px;
}
h1 {
  font-size: 32px;
  line-height: 40px;
  color: #fff;
}
h2 {
  font-size: 26px;
  line-height: 34px;
  color: #fff;
}
h3 {
  font-size: 24px;
  line-height: 32px;
  color: #fff;
}
h4 {
  font-size: 14px;
  line-height: 22px;
}
h5 {
  font-size: 12px;
  line-height: 20px;
}
.theme-btn {
  background: #4481eb;
  background: var(--theme-color);
  text-decoration: none;
  color: #fff;
  border-radius: 6px;
  width: 100%;
  padding: 10px 0px;
  text-align: center;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.theme-btn:hover {
  color: #4481eb;
  color: var(--theme-color);
  background: #fff;
  background: var(--white-color);
  text-decoration: none;
}
.menu-links.left {
  left: 0%;
}
/*hamburger styling starts*/
.hamburger-icon {
  margin-left: 20px;
  padding: 5px;
  background: transparent;
  flex-shrink: 0;
  width: 35px;
  border: transparent;
  display: none;
}
.hamburger-icon span {
  transform-origin: right;
  -webkit-transform-origin: right;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  display: block;
  width: 100%;
  background: #fff;
  background: var(--white-color);
  height: 2px;
}
.hamburger-icon span:nth-child(2) {
  margin: 5px 0px;
}
.hamburger-icon.ham-style span:first-child {
  transform: rotate(-45deg) translateX(2.4px);
  -webkit-transform: rotate(-45deg) translateX(2.4px);
}
.hamburger-icon.ham-style span:nth-child(2) {
  width: 8px;
  opacity: 0;
}
.hamburger-icon.ham-style span:last-child {
  transform: rotate(45deg) translateX(2.8px);
  -webkit-transform: rotate(45deg) translateX(2.8px);
}

/*header styling starts*/
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 50px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch {
  position: relative;
}
.switch::before {
  content: "\f185";
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  top: 50%;
  left: 10px;
  color: #fff;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-family: "FontAwesome";
  font-weight: 900;
  z-index: 1;
}
.switch::after {
  content: "\f186";
  position: absolute;
  font-size: 12px;
  line-height: 20px;
  top: 50%;
  right: 10px;
  color: #fff;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-family: "FontAwesome";
  font-weight: 900;
  z-index: 1;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ff6431;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  right: 4px;
  bottom: 4px;
  background-color: #fff;
  transition: 0.4s;
  z-index: 2;
}
input:checked + .slider {
  background-color: #4481eb;
  background-color: var(--theme-color);
}
input:focus + .slider {
  box-shadow: 0 0 1px #4481eb;
  box-shadow: 0 0 1px var(--theme-color);
  -webkit-box-shadow: 0 0 1px #4481eb;
  -webkit-box-shadow: 0 0 1px var(--theme-color);
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(-26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
/*Rounded slider ends*/
.navbar {
  padding: 32px 16px;
}
.header-inner {
  width: 100%;
}
.navbar-light .navbar-brand {
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  color: #fff;
  color: var(--white-color);
}
.navbar-light .navbar-brand:hover {
  color: #fff;
  color: var(--white-color);
}
.header-content {
  width: 100%;
}
.header-content form {
  width: 85%;
}
.header-content form input[type="search"]::-webkit-input-placeholder {
  color: #fff;
  color: var(--white-color);
}
.header-content form input[type="search"] {
  color: #fff;
  color: var(--white-color);
  height: 42px;
  padding: 10px 20px;
  border-radius: 50px;
  border: none;
}
.search-icon {
  position: relative;
  width: 85%;
}
.search-icon i {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  color: var(--white-color);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.light-theme .header-content form input[type="search"] {
  background: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.dark-theme .header-content form input[type="search"] {
  background: #272933;
}
.header-content a {
  display: inline-block;
}
.header-content .profile {
  margin: 0px 20px 0px 50px;
  color: #fff;
  color: var(--white-color);
}
.header-content .profile:hover {
  text-decoration: none;
}
.header-content .profile img {
  margin-right: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}
.header-content .notification {
  color: #fff;
  color: var(--white-color);
  line-height: 0px;
  width: 18px;
  height: 21px;
  line-height: 21px;
}
/*header styling ends*/

/*menu-links tyling starts*/
.menu-links {
  transition: all 0.8s;
  -webkit-transition: all 0.5s;
}
.menu-links ul {
  list-style: none;
  margin-bottom: 0px;
}
.menu-links ul li {
  margin-bottom: 42px;
}
.menu-links ul li:last-child {
  margin-bottom: 0px;
}
.menu-links ul li a {
  color: #fff;
  color: var(--white-color);
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.menu-links ul li:not(.active) a:hover {
  color: #4481eb;
  color: var(--theme-color);
}
.menu-links ul li a i {
  width: 32px;
  height: 32px;
  line-height: 20px;
  margin-right: 12px;
  padding: 6px 0px;
  text-align: center;
}
.menu-links ul li a.active i {
  border-radius: 2px;
  background-color: #4481eb;
  background-color: var(--theme-color);
  margin-right: 12px;
  color: #fff;
}
.light-theme .menu-links ul li.active a i {
  color: #fff;
}

/*nft-store-content styling starts*/
.nft-store-content {
  width: 100%;
  margin: 0px 0px 32px 32px;
}
.fire-bubble-art {
  padding: 32px;
  background-color: rgba(59, 65, 79, 0.5);
  background-color: var(--fire-bubble);
  border-radius: 16px;
}
.light-theme .fire-bubble-art {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.fire-bubble-art .fire-image {
  border-radius: 16px;
}
.fire-bubble-art .fire-width {
  width: calc(100% / 2);
}
.fire-bubble-art .fire-content {
  padding-left: 32px;
}
.light-theme .fire-content .fire-time {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.fire-content .fire-time {
  position: relative;
  margin: 16px 0px 24px;
  background: rgba(90, 94, 104, 0.5);
  background: var(--light-grey);
  padding: 32px;
  border-radius: 20px;
}
.fire-content .fire-time .middle-line {
  position: absolute;
  height: 60%;
  left: 48%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #fff;
  background: var(--white-color);
  width: 1px;
}
.fire-content .fire-time h4 {
  margin-bottom: 6px;
}
.fire-content .fire-time span {
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
}
.fire-content .fire-user img {
  margin-right: 5px;
}
.fire-content .fire-links {
  margin-top: 24px;
}
.fire-content .fire-links .heart {
  width: 42px;
  height: 42px;
  margin-right: 22px;
  text-align: center;
  padding: 9px 0px;
  border-radius: 6px;
  transition: all 0.5s;
  font-size: 22px;
  -webkit-transition: all 0.5s;
  border: 1px solid #fff;
  border: 1px solid var(--white-color);
  color: #fff;
  color: var(--white-color);
}
.fire-content .fire-links .heart:hover {
  background: #4481eb;
  background: var(--theme-color);
  border: 1px solid #4481eb;
  border: 1px solid var(--theme-color);
  color: #fff;
}
/*paint art styling starts*/
.paint-image {
  position: relative;
  margin-bottom: 0px;
  padding-top: 96%;
  border-radius: 16px;
}
.paint-image h1 {
  position: absolute;
  margin: 0px;
  right: 20px;
  bottom: 30px;
}

/*trending styling starts*/
.trending-title {
  margin: 32px 0px;
}
.trending-title h2 {
  margin-bottom: 0px;
}
.trending-title .theme-btn {
  width: 130px;
  display: inline-block;
}
.light-theme .trending-content {
  border-radius: 15px;
  background: rgba(59, 65, 79, 0.5);
  background: var(--fire-bubble);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.trending-content > img {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
}
.trending-desc {
  padding: 24px;
  position: relative;
}
.trending-desc .user-image {
  position: absolute;
  right: 18px;
  top: -30px;
  width: 60px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.trending-desc .user-title {
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.5);
  color: var(--deam-white);
  margin: 0px;
}
.trending-desc .user-position {
  text-transform: capitalize;
  margin: 16px 0px;
  font-size: 18px;
  line-height: 26px;
}
.trending-desc .bid h5 {
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.5);
  color: var(--deam-white);
  margin-bottom: 7px;
}
.trending-desc .bid span {
  font-size: 20px;
  line-height: 28px;
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .header-content form {
    width: 78%;
  }
  .fire-bubble-art {
    padding: 22px;
  }
  .fire-content .fire-time {
    padding: 22px;
  }
  .fire-content .fire-time .middle-line {
    left: 45%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  /*global styling starts*/
  h1 {
    font-size: 30px;
    line-height: 38px;
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
  h3 {
    font-size: 22px;
    line-height: 30px;
  }
  h4 {
    font-size: 14px;
    line-height: 22px;
  }
  h5 {
    font-size: 12px;
    line-height: 20px;
  }
  /*global styling ends*/
  .menu-links ul li {
    margin-bottom: 18px;
  }
  .header-content form {
    width: 75%;
  }
  .fire-bubble-art {
    padding: 18px;
  }
  .fire-bubble-art .fire-content {
    padding-left: 22px;
  }
  .fire-content .fire-time span {
    font-size: 20px;
    line-height: 28px;
  }
  .fire-content .fire-time {
    padding: 18px;
    margin: 12px 0px 20px;
  }
  .fire-content .fire-time .middle-line {
    left: 43%;
  }
  .trending-desc {
    padding: 20px;
  }
  .trending-desc .user-position {
    margin: 12px 0px;
  }
  .trending-desc .user-image {
    width: 40px;
    top: -22px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  /*global styling starts*/
  h1 {
    font-size: 26px;
    line-height: 34px;
  }
  h2 {
    font-size: 20px;
    line-height: 28px;
  }
  h3 {
    font-size: 18px;
    line-height: 26px;
  }
  h4 {
    font-size: 12px;
    line-height: 20px;
  }
  h5 {
    font-size: 12px;
    line-height: 20px;
  }
  body {
    font-size: 15px;
    line-height: 22px;
  }
  .theme-btn {
    font-size: 14px;
    line-height: 22px;
    padding: 6px 0px;
  }
  /*global styling ends*/
  /*navbar styling starts*/
  .navbar {
    padding: 16px;
  }
  .navbar-light .navbar-brand {
    font-size: 18px;
    line-height: 26px;
    margin: 0px;
  }
  .menu-links ul li {
    margin-bottom: 18px;
  }
  .menu-links ul li a {
    padding: 8px;
  }
  .menu-links ul li a i {
    margin-right: 10px;
  }
  .switch {
    width: 50px;
    height: 26px;
    margin-left: 20px;
  }
  .slider:before {
    width: 18px;
    height: 18px;
    right: 3px;
  }
  .header-content form {
    width: 75%;
  }
  .header-content .profile {
    margin: 0px 10px 0px 20px;
  }
  /*navbar styling ends*/
  /*fire up styling starts*/
  .nft-store-content {
    margin: 0px 0px 30px 22px;
  }
  .fire-bubble-art .fire-content {
    padding-left: 10px;
  }
  .fire-bubble-art {
    padding: 16px;
  }
  .fire-content .fire-time {
    padding: 14px 16px;
    margin: 10px 0px 15px;
  }
  .fire-content .fire-time h4 {
    margin-bottom: 2px;
  }
  .fire-content .fire-time .middle-line {
    left: 43%;
  }
  .fire-content .fire-links {
    margin-top: 18px;
  }
  .paint-image h1 {
    bottom: 22px;
  }
  .fire-content .fire-time span {
    font-size: 16px;
    line-height: 24px;
  }
  .fire-content .fire-user {
    font-size: 14px;
    line-height: 22px;
  }
  .fire-content .fire-user img {
    width: 30px;
  }
  .fire-content .fire-links .heart {
    width: 32px;
    height: 32px;
    font-size: 14px;
    padding: 4px 0px;
    margin-right: 14px;
  }
  /*trending styling starts*/
  .trending-title {
    margin: 22px 0px;
  }
  .trending-desc {
    padding: 12px;
  }
  .trending-desc .user-image {
    width: 35px;
    top: -20px;
    right: 14px;
  }
  .trending-desc .user-position {
    font-size: 16px;
    line-height: 24px;
    margin: 4px 0px 10px;
  }
  .trending-desc .bid h5 {
    margin-bottom: 4px;
  }
  .trending-desc .bid span {
    font-size: 18px;
    line-height: 26px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /*global styling starts*/
  h1 {
    font-size: 22px;
    line-height: 28px;
  }
  h2 {
    font-size: 16px;
    line-height: 24px;
  }
  h3 {
    font-size: 14px;
    line-height: 22px;
  }
  h4 {
    font-size: 12px;
    line-height: 20px;
  }
  h5 {
    font-size: 12px;
    line-height: 20px;
  }
  body {
    font-size: 14px;
    line-height: 22px;
  }
  .theme-btn {
    font-size: 14px;
    line-height: 22px;
    padding: 6px 0px;
  }
  /*navbar styling starts*/
  .hamburger-icon {
    display: block;
  }
  .navbar {
    padding: 16px 0px;
    flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
  }
  .navbar-light .navbar-brand {
    font-size: 18px;
    line-height: 26px;
    margin: 0px;
  }
  .light-theme .menu-links {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }
  .menu-links ul li {
    margin-bottom: 18px;
  }
  .menu-links ul li a {
    padding: 8px;
  }
  .menu-links ul li a i {
    margin-right: 10px;
  }
  .menu-links {
    position: fixed;
    top: 0;
    left: -100%;
    height: 100%;
    background: #141217;
    background: var(--body-color);
    width: 30%;
    padding: 50px 0px 0px 20px;
    z-index: 10;
  }
  .switch {
    width: 50px;
    height: 26px;
    margin-left: 20px;
  }
  .switch::before {
    left: 8px;
  }
  .slider:before {
    width: 18px;
    height: 18px;
    right: 3px;
  }
  .header-content form {
    width: 55%;
  }
  .light-theme .header-content form input[type="search"] {
    height: 36px;
  }
  .header-content .profile {
    margin: 0px 10px 0px 20px;
  }
  /*navbar styling ends*/
  /*global styling ends*/
  .nft-store-content {
    margin: 0px 0px 25px 0px;
  }
  .fire-bubble-art .fire-content {
    padding-left: 10px;
  }
  .fire-bubble-art {
    padding: 16px;
  }
  .fire-content .fire-time {
    padding: 12px 14px;
    margin: 8px 0px 12px;
    border-radius: 10px;
  }
  .fire-content .fire-time h4 {
    margin-bottom: 2px;
  }
  .fire-content .fire-time .middle-line {
    left: 43%;
  }
  .fire-content .fire-links {
    margin-top: 18px;
  }
  .paint-image h1 {
    bottom: 22px;
  }
  .fire-content .fire-time span {
    font-size: 14px;
    line-height: 22px;
  }
  .fire-content .fire-user {
    font-size: 14px;
    line-height: 22px;
  }
  .fire-content .fire-user img {
    width: 30px;
  }
  .fire-content .fire-links .heart {
    width: 32px;
    height: 32px;
    font-size: 14px;
    padding: 4px 0px;
    margin-right: 14px;
  }
  /*trending styling starts*/
  .trending-title {
    margin: 22px 0px;
  }
  .trending-desc {
    padding: 12px;
  }
  .trending-desc .user-image {
    width: 35px;
    top: -20px;
    right: 14px;
  }
  .trending-desc .user-position {
    font-size: 15px;
    line-height: 24px;
    margin: 4px 0px 10px;
  }
  .trending-desc .bid h5 {
    margin-bottom: 4px;
  }
  .trending-desc .bid span {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 767px) {
  /*global styling starts*/
  h1 {
    font-size: 22px;
    line-height: 28px;
  }
  h2 {
    font-size: 16px;
    line-height: 24px;
  }
  h3 {
    font-size: 14px;
    line-height: 22px;
  }
  h4 {
    font-size: 12px;
    line-height: 20px;
  }
  h5 {
    font-size: 12px;
    line-height: 20px;
  }
  body {
    font-size: 14px;
    line-height: 22px;
  }
  .theme-btn {
    font-size: 14px;
    line-height: 22px;
    padding: 6px 0px;
  }
  /*hamburger icon styling starts*/
  .hamburger-icon {
    margin-left: 10px;
  }
  /*navbar styling starts*/
  .navbar {
    padding: 16px 0px;
    flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
  }
  .navbar-light .navbar-brand {
    font-size: 18px;
    line-height: 26px;
    margin: 0px;
  }
  .navbar-light .navbar-brand img {
    width: 50px;
  }
  .menu-links {
    position: fixed;
    top: 0;
    left: -100%;
    height: 100%;
    background: #141217;
    background: var(--body-color);
    width: 70%;
    padding: 50px 0px 0px 20px;
    z-index: 10;
  }
  .light-theme .menu-links {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }
  .menu-links ul li {
    margin-bottom: 18px;
  }
  .menu-links ul li a {
    padding: 8px;
  }
  .menu-links ul li a i {
    margin-right: 10px;
  }
  .switch {
    width: 40px;
    height: 22px;
  }
  .switch::before {
    left: -2px;
  }
  .switch::after {
    right: 7px;
  }
  .slider {
    left: -7px;
  }
  .slider:before {
    width: 16px;
    height: 16px;
    right: 2px;
    bottom: 3px;
  }
  .header-content form {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 10px;
  }
  .search-icon {
    display: none;
  }
  .header-content .profile {
    display: none;
  }
  .hamburger-icon {
    display: block;
  }
  /*navbar styling ends*/
  /*global styling ends*/
  .nft-store-content {
    margin: 0px 0px 25px 0px;
  }
  .fire-bubble-art .fire-content {
    padding-left: 0px;
    margin-top: 15px;
  }
  .fire-bubble-art {
    padding: 16px;
    flex-direction: column;
  }
  .fire-bubble-art .fire-width {
    width: 100%;
  }
  .fire-content .fire-time {
    padding: 12px 14px;
    margin: 8px 0px 12px;
    border-radius: 10px;
  }
  .fire-content .fire-time h4 {
    margin-bottom: 2px;
  }
  .fire-content .fire-time .middle-line {
    left: 45%;
  }
  .fire-content .fire-links {
    margin-top: 18px;
  }
  .paint-image {
    margin-top: 30px;
  }
  .paint-image h1 {
    bottom: 22px;
  }
  .fire-content .fire-time span {
    font-size: 14px;
    line-height: 22px;
  }
  .fire-content .fire-user {
    font-size: 14px;
    line-height: 22px;
  }
  .fire-content .fire-user img {
    width: 30px;
  }
  .fire-content .fire-links .heart {
    width: 32px;
    height: 32px;
    font-size: 14px;
    padding: 4px 0px;
    margin-right: 14px;
  }
  /*trending styling starts*/
  .trending-title {
    margin: 25px 0px;
  }
  .trending-desc {
    padding: 12px;
  }
  .trending-content {
    margin-bottom: 25px;
  }
  .trending-grid .col-md-4:last-child .trending-content {
    margin-bottom: 0px;
  }
  .trending-desc .user-image {
    width: 35px;
    top: -20px;
    right: 14px;
  }
  .trending-desc .user-position {
    font-size: 15px;
    line-height: 24px;
    margin: 4px 0px 10px;
  }
  .trending-desc .bid h5 {
    margin-bottom: 4px;
  }
  .trending-desc .bid span {
    font-size: 16px;
    line-height: 24px;
  }
}

.formHolder {
  padding: 32px;
  background-color: rgba(59, 65, 79, 0.5);
  background-color: var(--fire-bubble);
  border-radius: 16px;
}

.formHolder .input {
  background: rgba(90, 94, 104, 0.5);
  background: var(--light-grey);
  padding: 32px;
  border-radius: 20px;
}

:root {
  --bg-color: #f5f5f5;
  --bg-round-a: #4481eb;
  --bg-round-b: #04befe;
  --panel-color: #fff;
  --title: #444;
  --bg-input: #e0e0e0;
  --input-icon: #8a8a8a;
  --input: #333;
  --input-hover: #222;
  --btn-color: #5995fd;
  --btn-text: #fff;
  --btn-hover: #4d84e2;
  --social-text: #444;
  --social-icon: #4481eb;
  --icon-color: #333;
  --check-text: #333;
  --check-hover: #333;
  --check-link: #4d84e2;
  --pass-color: #444;
  --key-color: #777;
  --pass-hover-color: #4d84e2;
  --keyboard-color: #e0e0e0;
  --key-letter: #333;
}

.login .container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  min-width: 100% !important;
  background-color: #f5f5f5;
  background-color: var(--bg-color);
  overflow: hidden;
}

.login .container:before {
  content: "";
  position: absolute;
  width: 2000px;
  height: 2000px;
  border-radius: 50%;
  background: linear-gradient(-45deg, #4481eb, #04befe);
  background: linear-gradient(-45deg, var(--bg-round-a), var(--bg-round-b));
  top: -10%;
  right: 48%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 6;
  transition: 1.8s ease-in-out;
}

.login .forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.login .signin-signup {
  position: absolute;
  top: 50%;
  left: 75%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 50%;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
  transition: 1s 0.7s ease-in-out;
}

.login form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 5rem;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  transition: 0.2s 0.7s ease-in-out;
}

.login form.sign-in-form {
  z-index: 2;
}

.login form.sign-up-form {
  z-index: 1;
  opacity: 0;
}

/* MODAL */

.login .btn-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: pink;
  font-size: 20px;
  color: white;
  padding: 10px 30px;
  cursor: pointer;
}

.login #popUpBox {
  width: 500px;
  overflow: hidden;
  background: pink;
  box-shadow: 0 0 10px black;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 9999;
  padding: 10px;
  text-align: center;
  display: none;
}

.login .title {
  font-size: 2.2rem;
  color: #444;
  color: var(--title);
  margin-bottom: 10px;
}

.login .input-field {
  width: 85%;
  height: 55px;
  background-color: #e0e0e0;
  background-color: var(--bg-input);
  margin: 10px 0;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 70% 15%;
  padding: 0 0.4rem;
}

.login .input-field i {
  text-align: center;
  line-height: 55px;
  color: #8a8a8a;
  color: var(--input-icon);
  font-size: 1.1rem;
}

.login .key {
  color: #777;
  color: var(--key-color);
  text-decoration: none;
}

.login .key:hover {
  color: #4d84e2;
  color: var(--pass-hover-color);
}

.login .pass {
  margin: 12px 0;
  color: #444;
  color: var(--pass-color);
}

.login .pass:hover {
  color: #4d84e2;
  color: var(--pass-hover-color);
}

.login #togglePassword {
  text-align: center;
  color: #8a8a8a;
  color: var(--input-icon);
}

.login #toggleReg {
  text-align: center;
  color: #8a8a8a;
  color: var(--input-icon);
}

.login .input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
  color: var(--input);
}

.login .input-field input::-webkit-input-placeholder {
  color: #222;
  color: var(--input-hover);
  font-weight: 500;
}

.login .input-field input:-ms-input-placeholder {
  color: #222;
  color: var(--input-hover);
  font-weight: 500;
}

.login .input-field input::placeholder {
  color: #222;
  color: var(--input-hover);
  font-weight: 500;
}

.login .btn {
  width: 150px;
  height: 49px;
  border: none;
  outline: none;
  border-radius: 49px;
  cursor: pointer;
  background-color: #5995fd;
  background-color: var(--btn-color);
  color: #fff;
  color: var(--btn-text);
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  transition: 0.5s;
}

.login .btn:hover {
  background-color: #4d84e2;
  background-color: var(--btn-hover);
}

.login .check {
  display: block;
  position: relative;
  margin: 12px 0;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.login .checkmark {
  color: #333;
  color: var(--check-text);
}

.login .checkmark a {
  color: #4d84e2;
  color: var(--check-link);
  text-decoration: underline;
}

.login .checkmark a:hover {
  color: #333;
  color: var(--check-hover);
}

.login .social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
  color: #444;
  color: var(--social-text);
}

.login .social-media {
  display: flex;
  justify-content: center;
}

.login .social-icon {
  height: 46px;
  width: 46px;
  border: 1px solid #333;
  border: 1px solid var(--icon-color);
  margin: 0 0.45rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #333;
  color: var(--icon-color);
  font-size: 1.1rem;
  border-radius: 50%;
  transition: 0.3s;
}

.login .social-icon:hover {
  color: #4481eb;
  color: var(--social-icon);
  border-color: #4481eb;
  border-color: var(--social-icon);
}

.login .icon-mode {
  height: 32px;
  width: 32px;
  border: 1px solid #333;
  border: 1px solid var(--icon-color);
  margin: 40px 5px 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #333;
  color: var(--icon-color);
  font-size: 1rem;
  border-radius: 50%;
  transition: 0.3s;
}

.login .icon-mode:hover {
  color: #4481eb;
  color: var(--social-icon);
  border-color: #4481eb;
  border-color: var(--social-icon);
}

.login .text-mode {
  padding: 0.5rem 0;
  font-size: 0.8rem;
  font-style: italic;
  color: #444;
  color: var(--social-text);
}

.login .panels-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.login .panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 7;
}

.login .left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.login .right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login .panel .content {
  color: #fff;
  color: var(--panel-color);
  transition: 0.9s 0.6s ease-in-out;
}

.login .panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.login .panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.login .btn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  /* width: 130px;
  height: 41px; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-weight: 600;
  font-size: 0.8rem;
}

.login .image {
  width: 90%;
  margin-top: 10px;
  transition: 1.1s 0.4s ease-in-out;
}

.login .right-panel .content,
.login .right-panel .image {
  -webkit-transform: translateX(800px);
          transform: translateX(800px);
}

/* ANIMATION */

.login .container.sign-up-mode:before {
  -webkit-transform: translate(100%, -50%);
          transform: translate(100%, -50%);
  right: 52%;
}

.login .container.sign-up-mode .left-panel .image,
.login .container.sign-up-mode .left-panel .content {
  -webkit-transform: translateX(-800px);
          transform: translateX(-800px);
}

.login .container.sign-up-mode .right-panel .content,
.login .container.sign-up-mode .right-panel .image {
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
}

.container.sign-up-mode .left-panel {
  pointer-events: none;
}

.login .container.sign-up-mode .right-panel {
  pointer-events: all;
}

.login .container.sign-up-mode .signin-signup {
  left: 25%;
}

.login .container.sign-up-mode form.sign-in-form {
  z-index: 1;
  opacity: 0;
}

.login .container.sign-up-mode form.sign-up-form {
  z-index: 2;
  opacity: 1;
}

/* KEYBOARD */

.login .keyboard {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 5px 0;
  background: #e0e0e0;
  background: var(--keyboard-color);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: bottom 0.4s;
  z-index: 999;
}

.login .keyboard--hidden {
  bottom: -100%;
}

.login .keyboard__keys {
  text-align: center;
}

.login .keyboard__key {
  height: 45px;
  width: 6%;
  max-width: 90px;
  margin: 3px;
  border-radius: 4px;
  border: none;
  background: rgba(255, 255, 255, 0.2);
  color: #333;
  color: var(--key-letter);
  font-size: 1.05rem;
  outline: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.login .keyboard__key:active {
  background: rgba(255, 255, 255, 0.12);
}

.login .keyboard__key--wide {
  width: 12%;
}

.login .keyboard__key--extra-wide {
  width: 36%;
  max-width: 500px;
}

.login .keyboard__key--activatable::after {
  content: "";
  top: 10px;
  right: 10px;
  position: absolute;
  width: 8px;
  height: 8px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
}

.login .keyboard__key--active::after {
  background: #08ff00;
}

.login .keyboard__key--dark {
  background: rgba(0, 0, 0, 0.25);
}

/* MEDIA SCREEN */

@media (max-width: 870px) {
  .login .container {
    min-height: 800px;
    height: 100vh;
  }

  .login .container::before {
    width: 1500px;
    height: 1500px;
    left: 30%;
    bottom: 68%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    right: auto;
    right: initial;
    top: auto;
    top: initial;
    transition: 2s ease-in-out;
  }

  .login .signin-signup {
    width: 100%;
    left: 50%;
    top: 95%;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .login .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .login .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
  }

  .login .panel .content {
    padding-right: 15%;
    transition: 0.9s 0.8s ease-in-out;
  }

  .login .panel h3 {
    font-size: 1.2rem;
  }

  .login .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .login .btn.transparent {
    /* width: 110px;
    height: 35px; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 0.7rem;
  }

  .login .image {
    display: none;
  }

  /*.image {
          width: 200px;
          transition: 0.9s 0.6s ease-in-out;
      }*/

  .login .left-panel {
    grid-row: 1 / 2;
  }

  .login .right-panel {
    grid-row: 3 / 4;
  }

  .login .right-panel .content,
  .login .right-panel .image {
    -webkit-transform: translateY(300px);
            transform: translateY(300px);
  }

  .login .container.sign-up-mode:before {
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%);
    bottom: 32%;
    right: auto;
    right: initial;
  }

  .login .container.sign-up-mode .left-panel .image,
  .login .container.sign-up-mode .left-panel .content {
    -webkit-transform: translateY(-300px);
            transform: translateY(-300px);
  }

  .login .container.sign-up-mode .signin-signup {
    top: 5%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    left: 50%;
  }

  .login .keyboard,
  .login .key {
    opacity: 0;
    visibility: hidden;
    font-size: 0.1px;
  }
}

@media (max-width: 570px) {
  .login form {
    padding: 0 1.5rem;
  }

  .login .image {
    display: none;
  }

  .login .panel .content {
    padding: 0.5rem 1rem;
  }

  /* .login .panel p { */
  /* opacity: 0; */

  /* } */

  .login .container:before {
    bottom: 75%;
    left: 50%;
  }

  .login .container.sign-up-mode:before {
    bottom: 24%;
    left: 50%;
  }

  .login .field-icon {
    float: right;
    margin-left: 300px;
    margin-top: -55px;
    position: relative;
    z-index: 1;
  }
}

@media (max-width: 385px) {
  .login .field-icon {
    float: right;
    margin-left: 260px;
    margin-top: -55px;
    position: relative;
    z-index: 1;
  }
}

@media (max-width: 350px) {
  .login .field-icon {
    float: right;
    margin-left: 200px;
    margin-top: -55px;
    position: relative;
    z-index: 1;
  }
}

.spinner {
  /* display: inline-block; */
  margin: auto;
  width: 20px;
  height: 20px;
  background-color: #f1f1f9;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: 5px solid black;
  border-bottom-color: transparent;
  position: relative;
  animation: spinner 1s linear infinite paused;
  -webkit-animation: spinner 1s linear infinite;
}

@-webkit-keyframes spinner {
  to {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    -ms-transform: rotate(1turn);
    -o-transform: rotate(1turn);
  }
}

@keyframes spinner {
  to {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    -ms-transform: rotate(1turn);
    -o-transform: rotate(1turn);
  }
}

.blogCard h1 {
  margin-bottom: 0;
  color: black;
}

.blogCard p {
  color: black;
}

